<template>
    <admin-dashboard-layout>
        <div>
            <div>
                UPDATE EXISTING CLAIM
            </div>
            POST /api/claims<br>
            header auth: XXXXXX<br>
            BODY<br>
            { foo: bar }<br>
        </div>
    </admin-dashboard-layout>
</template>

<script>

    import AdminDashboardLayout from "../../layouts/AdminDashboardLayout";

    export default {
        name: "Index",
        components: {AdminDashboardLayout},
        data: function () {
            return {}
        },
    }
</script>

<style scoped>

</style>
